//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { absToRelPath } from './helpers.coverage';
import styles from './TestCoverage.module.scss';

import { saveAs } from 'file-saver'; 
import genericCalls from '../API/genericCalls';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

const prepAndSendCoverageTotal = (event: MouseEvent, apiPrefix:string, keepalive?: boolean)=>prepAndSendCoverage(event, apiPrefix ?? '', keepalive, true);

const prepAndSendCoverage = async (event: MouseEvent, apiPrefix:string, keepalive?: boolean, shouldGetTotalCoverage?:boolean) => {
    // @ts-ignore
    if (!window.__coverage__) { return }
    // @ts-ignore
    const coverageRel = absToRelPath(window.__coverage__, `${process.env.REACT_APP_BUILD_ROOT}/` ?? '');
    let url = apiPrefix + '/_local-dev-api/coverage';
    if (!shouldGetTotalCoverage) {
      url = url + '-diff';
    }
    const res = await genericCalls.post(url, coverageRel, { keepalive });
    if (res?.url) {
      window.open(res.url, 'coverage-report');
    }
    // TODO: save to actual backend after implemented (concatenate multiple coverage reports using nyc and generate report `yarn nyc report --reporter=html`)
    return res?.rc === 0
  }

interface UseTestCoverageProps{
  apiPrefix?: string
}

function useTestCoverage({apiPrefix}: UseTestCoverageProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pos, setPos] = useState('bottomLeft');
  
  const downloadJson = (obj:any) => {
    const str = JSON.stringify(obj);
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
        type: "application/json;charset=utf-8"
    });
    const fileName = `test_coverage_${new Date()}.json`;
    saveAs(blob, fileName);
  }
  
    const renderOpen = () =>{
      return (
          <div className={styles.coverageSettings}>
            <p>
              Firefox prerequisits: type "about:config" into address bar and press enter. Set dom.require_user_interaction_for_beforeunload=false
          </p>
          <div className='actionBtns' style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{border: '1px solid black', padding: '5px'}}>
            <button disabled={isLoading} onClick={
              async(e) => {
                setIsLoading(true);
                const sendSuccess = await prepAndSendCoverage(e, apiPrefix ?? '');
                setIsLoading(false);
              }}
            >
              diff
              </button>
            </div>
            <div style={{border: '1px solid black', padding: '5px'}}>
              <button disabled={isLoading} onClick={
                async(e) => {
                  setIsLoading(true);
                  const sendSuccess = await prepAndSendCoverageTotal(e, apiPrefix ?? '');
                  setIsLoading(false);
                }}
              >
                total
              </button>
              <button
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  const res = await genericCalls.get(apiPrefix + '/_local-dev-api/coverage/stop');
                  if (res?.rc === 0) {
                    const confirm = window.confirm('data cleared successfully, reload page to start fresh?');
                    if (confirm){
                      window.location.reload();
                    }
                  } else {
                    alert(res?.msg);
                  }
                  setIsLoading(false);
                  console.log(res);
                }}
              >
                clear
              </button>
            </div>
            <div style={{border: '1px solid black', padding: '5px'}}>
              <button
                disabled={isLoading}
                onClick={() => {
                // @ts-ignore
                downloadJson(window.__coverage__)
              }}>download</button>
            </div>
            {<div style={{visibility: isLoading ? 'visible' : 'hidden'}}><LoadingSpinner size={"2rem"}/></div>}
          </div>
          </div>
      )
    }
  
    const renderCoverageDiv = () => {
      return (
        <>
              <span title={`${isOpen ? 'close' : 'open'} coverage report settings`} onClick={() => { setIsOpen(!isOpen) }} className={[styles.openBtn, styles.action].join(' ')}>Coverage</span>
          {pos === 'bottomLeft'
            ? <span title="move to bottom right" className={[styles.movePos, styles.action].join(' ')} onClick={() => { setPos('bottomRight') }}>{`>`}</span>
            : <span title="move to bottom left" className={[styles.movePos, styles.action].join(' ')} onClick={() => { setPos('bottomLeft') }}>{`<`}</span>}
        </>
        
        
      )
    }
    
    useEffect(() => {
      const beforeunload = async (event: any) => {
        // prepAndSendCoverage(event,'', true);
        const msg = "Test-coverage is enabled. Please do not navigate away from this page until you have clicked the 'save' button to send the coverage report. Navigate away now?";
        event.preventDefault()
        event.returnValue = msg;
        return msg;
      }
        if(process.env.REACT_APP_TESTCOVERAGE==='1'){
          // TODO: seems like this is not useful due to browser preventing send of api call here ? keepalive=true might fix it, but not supported in firefox yet?
          
            window.addEventListener("beforeunload", beforeunload);
    
          return () => {
            window.removeEventListener("beforeunload", beforeunload);
          }
        }
    }, []);
    
    return ({
        renderOpen,
        renderCoverageDiv,
        isOpen,
        pos
  }
  )
}

export default useTestCoverage