import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import './errorReporting';
import LoadingSpinner from './Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';
import { dynamicConfig } from './config/dynamicConfig/dynamicConfig';
import { setFavicon } from './Widgets/common/helpers';
import TestCoverage from './TestCoverage/TestCoverage';

(async () => {
    ReactDOM.render(<LoadingSpinner/>, document.getElementById('root'));

    window.rxStore = configureStore();
    document.title = `${dynamicConfig.pageTitlePrefix}${dynamicConfig.pageTitle}`;
    dynamicConfig.favicons.map((icon) => setFavicon(icon));
    
    await i18n.init();

    ReactDOM.render(
        <Provider store={window.rxStore}>
                <TestCoverage apiPrefix='/_me'/>
                <App />
        </Provider>
        , document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();